.pannel-header {
  display: inline-block;
  text-align: center;
  font-weight: 550;
  font-size: 12px;
  color: white;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 5px;
  background-color: #386FA4;
  border: 1px solid #386FA4;
  border-top-right-radius: .25rem;
  border-top-left-radius: .25rem;
  height: 50px;
  width: 300px;
  white-space: nowrap;
}
.pannel-body {
  font-size: 11px;
  border: 1px solid #386FA4;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
  height: 200px;
  width: 300px;
  overflow: auto;
}
.pannel {
  width: 300px;
  margin-bottom: 15px;
}
.rt-td {
  width: auto !important;
}
.tr-th {
  width: auto !important;
}
tr td {
  white-space: nowrap;
}
.navbar {
  margin-bottom: 20px;
  background-color: #1D3461;
}
.navbar-brand {
 margin: 0px !important;
}
.nav-link {
  color: #f0f0f0;
}

.calendario {
  height: 860px;
}

.status-vermelho {
  color: red;
  font-weight: bold;
}

.status-azul {
  color: blue;
  font-weight: bold;
}

.status-preto {
  color: inherit;
}

.status-cinza {
  color: #c7c7c7;
  font-weight: bold;
}
